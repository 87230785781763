<template>
    <div id="fh5co-started" class="fh5co-bg" style="background-image:url(../assets/images/image2.jpg);">
		<div class="overlay"></div>
		<div class="container">
			<div class="row animate-box">
				<div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
					<h2 style="font-family: 'Oleo Script', Sans-serif;">{{ config.language === 1 ? 'Reservation' : 'Reservasi' }}</h2>
					<p>{{ config.language === 1 ? 'It is an honor and happiness for we as a family if you wish to attend to give blessings to the bride and groom Thank you for your presence and blessings.' : 'Merupakan suatu kehormatan dan kebahagiaan bagi kami sekeluarga apabila Bapak/Ibu/Saudara/i berkenan hadir untuk memberikan doa restu kepada kedua mempelai atas kehadiran serta doa restu, ucapkan terimakasih.' }}</p>
				</div>
			</div>
			<div class="row animate-box">
				<div class="col-md-10 col-md-offset-1">
					<form class="form-inline">
						<div class="col-md-8 col-md-offset-2">
							<div class="form-group">
								<label for="name" class="sr-only">
									{{ config.language === 1 ? 'Name' : 'Nama' }}
								</label>
								<input type="name" v-model="name" class="form-control" id="name" :placeholder="config.language === 1 ? 'Name' : 'Nama'" autocomplete="off" required>
							</div>
						</div>
                        <div class="col-md-8 col-md-offset-2">
                            <div class="form-group">
								<input type="radio" id="one" value="Iya, Saya akan Hadir" v-model="picked" required/>
                                <label for="one">{{ config.language === 1 ? 'Yes, I will attend' : 'Iya, Saya akan Hadir' }}</label>
							</div>
                        </div>
                        <div class="col-md-8 col-md-offset-2">
                            <div class="form-group">
								<input type="radio" id="two" value="Maaf, Saya Tidak Bisa Hadir" v-model="picked" required/>
                                <label for="two">{{ config.language === 1 ? 'Sorry, I cant attend' : 'Maaf, Saya Tidak Bisa Hadir' }}</label>
							</div>
                        </div>
						<div class="col-md-4 col-md-offset-4">
							<a :href="'https://api.whatsapp.com/send?phone=' + client.wa_number + '&text= Hai, saya ' + name + ' ingin konfirmasi kehadiran undangan pernikahan bahwa ' + picked + '. Terima kasih'" target="_blank" type="submit" class="btn btn-default btn-block">RSVP Via Whatsapp</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['client', 'config'],
    data(){
        return{
            name: '',
            picked: '',
        }
    },
    methods: {

    }
}
</script>