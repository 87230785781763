<template>
    <div id="fh5co-couple">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center fh5co-heading animate-box">
            <span>Our Special Bride</span>
            <h2 style="font-family: 'Oleo Script', Sans-serif;">{{ config.language === 1 ? "The Bride's" : 'Mempelai' }}</h2>
            <!-- <h3>Invite You</h3>
            <p>To Celebrate Our Marriage</p> -->
          </div>
        </div>
        <div class="couple-wrap ">
          <div class="couple-half animate-box">
            <div class="groom" v-if="config.profile_picture !== 1">
              <img :src="client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita" alt="groom" class="img-responsive">
            </div>
            <div class="desc-groom" :style="getDescGroomStyle()">
              <h3 style="font-family: 'Lily Script One', cursive;">
                {{ client.hajat === 'pria' ? client.gelar_pria : client.gelar_wanita }}
              </h3>
              <p><strong>{{ config.language === 1 ? (client.hajat === 'pria' ? 'Son of' : 'Daughter of') : (client.hajat === 'pria' ? 'Putra Dari' : 'Putri Dari') }}</strong></p>
              <p>{{ client.hajat === 'pria' ? client.nama_ayah_pria : client.nama_ayah_wanita }}</p>
              <p>&amp;</p>
              <p>{{ client.hajat === 'pria' ? client.nama_ibu_pria : client.nama_ibu_wanita }}</p>
            </div>
          </div>
          <p class="heart text-center">
            <i class="icon-heart2"></i>
          </p>
          <div class="couple-half animate-box">
            <div class="bride" v-if="config.profile_picture !== 1">
              <img :src="client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita" alt="groom" class="img-responsive">
            </div>
            <div class="desc-bride" :style="getDescBrideStyle()">
              <h3 style="font-family: 'Lily Script One', cursive;">
                {{ config.language === 1 ? (client.hajat === 'wanita' ? 'Son of' : 'Daughter of') : (client.hajat === 'wanita' ? 'Putra Dari' : 'Putri Dari') }}
              </h3>
              <p><strong>{{ client.hajat === 'wanita' ? 'Putra Dari' : 'Putri Dari' }}</strong></p>
              <p>{{ client.hajat === 'wanita' ? client.nama_ayah_pria : client.nama_ayah_wanita }}</p>
              <p>&amp;</p>
              <p>{{ client.hajat === 'wanita' ? client.nama_ibu_pria : client.nama_ibu_wanita }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['client', 'config'],
  data() {
    return{

    }
  },
  methods: {
    getDescGroomStyle() {
      if (window.innerWidth > 480 && this.config.profile_picture === 1) {
        return { 'padding-right': '50px' };
      } else {
        return {}; // Style default yang didefinisikan dalam media query
      }
    },
    getDescBrideStyle() {
      if (window.innerWidth > 480 && this.config.profile_picture === 1) {
        return { 'padding-left': '50px' };
      } else {
        return {}; // Style default yang didefinisikan dalam media query
      }
    }
  }
}
</script>